.widget-header {
  background-color: var(--header-color);

  &-sub {
    display: flex;
    align-items: center;
    padding: 8px;
    animation: fadeInSub;
    animation-duration: 0.8s;

    &-title {
      margin-top: 0;
      line-height: 22px;
      font-size: 17px;
      font-weight: 600;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--header-contrast-color);
      user-select: none;
      margin-left: 8px;
    }
  }

  &-main {
    padding: 25px;
    padding-bottom: 0px;
    animation: fadeIn;
    animation-duration: 0.8s;

    &-logo {
      object-fit: contain;
      width: auto;
      max-width: 200px;
      height: 40px;

      path {
        fill: var(--header-contrast-color);
      }
    }

    &-title {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;

      &-name {
        text-transform: capitalize;
      }

      span {
        font-size: 22px;
        line-height: 26px;
        font-weight: 600;
        margin-right: 5px;
        color: var(--header-contrast-color);
        user-select: none;
      }
    }

    &-description {
      font-weight: 400;
      margin-top: 6px;
      font-size: 16px;
      line-height: 19px;
      color: var(--header-contrast-color);
      user-select: none;
    }

    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .header-exit-active {
    > * {
      animation: headerFadeOut 300ms both ease-out;
    }
  }

  .header-enter-active,
  .header-enter-done {
    > * {
      animation: headerFadeIn 500ms both ease-in;
    }
  }
}

@keyframes headerFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes headerFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

[dir=rtl] .widget-header-main-close {
  right: auto !important;
  left: 10px;
}

[dir=rtl] .widget-header-sub-title {
  margin-right: 8px;
  margin-left: 0px !important;
}