.main-app {
  overflow: hidden;
}

.content-container {
  background-color: var(--background-color);
  position: relative;
}

.main {
  display: grid;
  grid-template: "main";
  flex: 1;
  position: relative;
  overflow: hidden;
}

.main > * {
  grid-area: main; /* Transition: make sections overlap on same cell */
  flex: 1 1 auto;
  position: relative;
}

.main > :first-child {
  z-index: 1; /* Prevent flickering on first frame when transition classes not added yet */
}

.mr-5 {
  margin-right: 5px;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInSub {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
