.widget-menu-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 9px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 1px solid transparent;
  border-radius: var(--border-radius-60);
  background-color: var(--background-color);
  box-sizing: border-box;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1);

  &:last-of-type {
    margin-bottom: 0px;
  }

  &:hover {
    background-color: var(--background-color-darker);
  }

  &-icon {
    width: 44px;
    height: 44px;
    min-width: 44px;
    background-color: #fff;
    border-radius: 44px;
    margin-right: 12px;
    padding: 10px;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-labels {
    flex-grow: 1;
  }

  &-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 3px;
    color: var(--primary-text-color);
  }

  &-description {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--secondary-text-color);
  }
}

[dir=rtl] .widget-menu-button {
  .widget-menu-button-icon {
    margin-right: 0px !important;
    margin-left: 12px;
  }
}