.form-send-button {
  font-family: Inter, sans-serif;
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
  border-radius: var(--border-radius-150);
  box-sizing: border-box;
  padding: 12px 26px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  border: none;
  text-align: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &--disabled {
    background-color: var(--background-color-darker) !important;
    color: var(--background-color-dark) !important;
    opacity: 1 !important;
    cursor: not-allowed !important;
  }
}
