.form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  &-description {
    font-size: 16px;
    line-height: 19px;
    color: var(--secondary-text-color);
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  &-label {
    font-size: 15px;
    line-height: 19px;
    text-align: left;
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 5px;
    color: var(--primary-text-color);

    &--required {
      color: #ed4337;
      margin-left: 3px;
    }
  }
}

[dir=rtl] .form-item-label {
  text-align: right;
}