.one-to-five-form-item {
  &-items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &-item {
    cursor: pointer;
    padding: 0;
    background-color: var(--background-color-dark);
    color: var(--secondary-text-color);
    border-radius: var(--border-radius-25);
    width: calc(20% - 2px);
    text-align: center;
    line-height: 44px;
    min-height: 44px;
    font-size: 17px;
    font-weight: 500;

    &:hover,
    &--active {
      background-color: var(--primary-color);
      color: var(--primary-contrast-color);
    }
  }

  &-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      color: var(--secondary-text-color);
      font-size: 13px;
      font-weight: 500;
    }
  }
}
