.capture-buttons {
  display: flex;

  .capture-button {
    border-radius: var(--border-radius-150);
    background-color: var(--background-color-darker);
    box-sizing: border-box;
    padding: 8px 12px;
    width: auto;
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 39px;

    svg {
      width: 19px;
      height: 19px;
      object-fit: contain;

      path,
      g {
        fill: var(--primary-text-color);
      }
    }

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      color: var(--primary-text-color);
      font-weight: 400;
      margin-left: 8px;
    }

    &:last-of-type {
      margin-right: 0px;
    }

    &:hover {
      background-color: var(--background-color-dark);
    }
  }

  .capture-added-button {
    border-radius: var(--border-radius-40);
    background-color: var(--background-color-darker);
    box-sizing: border-box;
    padding: 6px 12px;
    padding-right: 8px;
    width: auto;
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 0px;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 39px;

    svg {
      width: 19px;
      height: 19px;
      object-fit: contain;

      path,
      g {
        fill: var(--primary-text-color);
      }
    }

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      color: var(--primary-text-color);
      font-weight: 400;
      margin-left: 8px;
    }

    &:last-of-type {
      margin-right: 0px;
    }

    &-remove {
      cursor: pointer;
      margin-left: 5px;
      width: 27px;
      height: 27px;
      border-radius: var(--border-radius-25);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: var(--background-color-dark);
      }
    }
  }
}

[dir="rtl"] .capture-buttons .capture-button {
  margin-left: 0px;
  margin-right: 0px;

  &:last-of-type {
    margin-right: 8px !important;
  }
}

[dir="rtl"] .capture-added-button {
  padding: 6px 12px 6px 8px;
}

[dir="rtl"] .capture-buttons .capture-added-button span,
[dir="rtl"] .capture-buttons .capture-button span {
  margin-left: 0px !important;
  margin-right: 8px;
}

[dir="rtl"] .capture-buttons .capture-added-button-remove {
  margin-left: 0px;
  margin-right: 5px;
}
