.text-form-item {
  display: flex;

  &-input {
    width: 100%;
    font-size: 15px;
    appearance: none;
    padding: 11px 12px;
    outline: 0;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--primary-text-color);
    font-weight: 400;
    border-radius: var(--border-radius-40);

    &:focus {
      border-color: var(--primary-color);
    }

    &--invalid,
    &--invalid:focus {
      border: 1px solid #ed4337;
    }

    @media screen and (max-device-width: 600px) {
      font-size: 16px;
    }
  }
}
