.main-menu {
  background: linear-gradient(
    180deg,
    var(--header-color) 0%,
    var(--header-color) 50px,
    var(--background-color) 50px,
    var(--background-color) 100%
  );

  .widget-menu-buttons {
    z-index: 10;
  }

  .powered-by {
    padding-top: 0px;
  }

  &--inner {
    animation: fadeInUp;
    animation-duration: 0.3s;
  }
}
