.multiple-choice-form-item {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  label {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    line-height: 20px;
    margin-bottom: 8px;
    margin-top: 8px;

    span {
      font-size: 16px;
      color: var(--primary-text-color);
      text-align: left;
      line-height: 20px;
      font-weight: 400;
      margin-left: 5px;
    }
  }

  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    background-color: var(--background-color);
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 22px;
    height: 22px;
    border: 2px solid var(--border-color);
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--primary-color);
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  input[type="radio"]:checked {
    border: 2px solid var(--primary-color);
  }
}

[dir="rtl"] .multiple-choice-form-item label span {
  text-align: right;
  margin-left: 0px !important;
  margin-right: 5px;
}
