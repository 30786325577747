.rating-form-item {
  &-items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .emojigroup {
    --normal: var(--background-color-darker);
    --normal-shadow: var(--background-color-dark);
    --normal-mouth: var(--border-color);
    --normal-eye: var(--secondary-text-color);
    --active: #f8da69;
    --active-shadow: #f4b555;
    --active-mouth: #f05136;
    --active-eye: #313036;
    --active-tear: #76b5e7;
    --active-shadow-angry: #e94f1d;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 8px;
  }

  .emoji-item-required .emojigroup {
    --normal: #fde6e6;
    --normal-shadow: #ffbdbd;
  }

  .emojigroup li {
    position: relative;
    border-radius: 50%;
    background: var(--sb, var(--normal));
    box-shadow: inset 3px -3px 4px var(--sh, var(--normal-shadow));
    transition: background 0.4s, box-shadow 0.4s, transform 0.3s;
    -webkit-tap-highlight-color: transparent;
  }

  .emojigroup li:not(:last-child) {
    margin-right: 12px;
  }

  @media only screen and (max-width: 330px) {
    .emojigroup li:not(:last-child) {
      margin-right: 6px;
    }
  }

  .emojigroup li div {
    width: 45px;
    height: 45px;
    position: relative;
    transform: perspective(240px) translateZ(4px);
  }

  .emojigroup li div svg,
  .emojigroup li div:before,
  .emojigroup li div:after {
    display: block;
    position: absolute;
    left: var(--l, 9px);
    top: var(--t, 13px);
    width: var(--w, 8px);
    height: var(--h, 2px);
    transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
  }

  .emojigroup li div svg {
    fill: none;
    stroke: var(--s);
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke 0.4s;
  }

  .emojigroup li div svg.emoji-item-eye {
    --s: var(--e, var(--normal-eye));
    --t: 17px;
    --w: 10px;
    --h: 4px;
  }

  .emojigroup li div svg.emoji-item-eye.emoji-item-right {
    --l: 28px;
  }

  .emojigroup li div svg.emoji-item-mouth {
    --s: var(--m, var(--normal-mouth));
    --l: 14px;
    --t: 25px;
    --w: 18px;
    --h: 10px;
  }

  .emojigroup li div:before,
  .emojigroup li div:after {
    content: "";
    z-index: var(--zi, 1);
    border-radius: var(--br, 1px);
    background: var(--b, var(--e, var(--normal-eye)));
    transition: background 0.4s;
  }

  .emojigroup li.emoji-item-angry {
    --step-1-rx: -24deg;
    --step-1-ry: 20deg;
    --step-2-rx: -24deg;
    --step-2-ry: -20deg;
  }

  .emojigroup li.emoji-item-angry div:before {
    --r: 20deg;
    top: 12px;
    left: 11px;
  }

  .emojigroup li.emoji-item-angry div:after {
    --l: 23px;
    --r: -20deg;
    top: 12px;
    left: 28px;
  }

  .emojigroup li.emoji-item-angry div svg.emoji-item-eye {
    stroke-dasharray: 4.55;
    stroke-dashoffset: 8.15;
  }

  .emojigroup li.emoji-item-angry:hover,
  .emojigroup li.emoji-item-angry.emoji-item--active {
    animation: angry 1s linear;
  }

  .emojigroup li.emoji-item-angry:hover div:before,
  .emojigroup li.emoji-item-angry.emoji-item--active div:before {
    --middle-y: -2px;
    --middle-r: 22deg;
    animation: toggle 0.8s linear forwards;
  }

  .emojigroup li.emoji-item-angry:hover div:after,
  .emojigroup li.emoji-item-angry.emoji-item--active div:after {
    --middle-y: 1px;
    --middle-r: -18deg;
    animation: toggle 0.8s linear forwards;
  }

  .emojigroup li.emoji-item-sad {
    --step-1-rx: 20deg;
    --step-1-ry: -12deg;
    --step-2-rx: -18deg;
    --step-2-ry: 14deg;
  }

  .emojigroup li.emoji-item-sad div:before,
  .emojigroup li.emoji-item-sad div:after {
    --b: var(--active-tear);
    --sc: 0;
    --w: 5px;
    --h: 5px;
    --t: 15px;
    --br: 50%;
  }

  .emojigroup li.emoji-item-sad div:after {
    --l: 25px;
  }

  .emojigroup li.emoji-item-sad div svg.emoji-item-eye {
    --t: 16px;
  }

  .emojigroup li.emoji-item-sad div svg.emoji-item-mouth {
    --t: 25px;
    stroke-dasharray: 9.5;
    stroke-dashoffset: 33.25;
  }

  .emojigroup li.emoji-item-sad:hover div:before,
  .emojigroup li.emoji-item-sad:hover div:after,
  .emojigroup li.emoji-item-sad.emoji-item--active div:before,
  .emojigroup li.emoji-item-sad.emoji-item--active div:after {
    animation: tear 0.6s linear forwards;
  }

  .emojigroup li.emoji-item-ok {
    --step-1-rx: 4deg;
    --step-1-ry: -22deg;
    --step-1-rz: 6deg;
    --step-2-rx: 4deg;
    --step-2-ry: 22deg;
    --step-2-rz: -6deg;
  }

  .emojigroup li.emoji-item-ok div:before {
    --l: 15px;
    --t: 15px;
    --h: 4px;
    --w: 4px;
    --br: 50%;
    box-shadow: 12px 0 0 var(--e, var(--normal-eye));
  }

  .emojigroup li.emoji-item-ok div:after {
    --l: 16px;
    --t: 27px;
    --w: 14px;
    --h: 2px;
    --br: 1px;
    --b: var(--m, var(--normal-mouth));
  }

  .emojigroup li.emoji-item-ok:hover div:before,
  .emojigroup li.emoji-item-ok.emoji-item--active div:before {
    --middle-s-y: 0.35;
    animation: toggle 0.2s linear forwards;
  }

  .emojigroup li.emoji-item-ok:hover div:after,
  .emojigroup li.emoji-item-ok.emoji-item--active div:after {
    --middle-s-x: 0.5;
    animation: toggle 0.7s linear forwards;
  }

  .emojigroup li.emoji-item-good {
    --step-1-rx: -14deg;
    --step-1-rz: 10deg;
    --step-2-rx: 10deg;
    --step-2-rz: -8deg;
  }

  .emojigroup li.emoji-item-good div:before {
    --b: var(--m, var(--normal-mouth));
    --w: 5px;
    --h: 5px;
    --br: 50%;
    --t: 22px;
    --zi: 0;
    opacity: 0.5;
    box-shadow: 16px 0 0 var(--b);
    filter: blur(2px);
  }

  .emojigroup li.emoji-item-good div:after {
    --sc: 0;
  }

  .emojigroup li.emoji-item-good div svg.emoji-item-eye {
    --t: 15px;
    --sc: -1;
    stroke-dasharray: 4.55;
    stroke-dashoffset: 8.15;
  }

  .emojigroup li.emoji-item-good div svg.emoji-item-mouth {
    --t: 22px;
    --sc: -1;
    stroke-dasharray: 13.3;
    stroke-dashoffset: 23.75;
  }

  .emojigroup li.emoji-item-good:hover div svg.emoji-item-mouth,
  .emojigroup li.emoji-item-good.emoji-item--active div svg.emoji-item-mouth {
    --middle-y: 1px;
    --middle-s: -1;
    animation: toggle 0.8s linear forwards;
  }

  .emojigroup li.emoji-item-happy div {
    --step-1-rx: 18deg;
    --step-1-ry: 24deg;
    --step-2-rx: 18deg;
    --step-2-ry: -24deg;
  }

  .emojigroup li.emoji-item-happy div:before {
    --sc: 0;
  }

  .emojigroup li.emoji-item-happy div:after {
    --b: var(--m, var(--normal-mouth));
    --l: 14px;
    --t: 25px;
    --w: 18px;
    --h: 8px;
    --br: 0 0 8px 8px;
  }

  .emojigroup li.emoji-item-happy div svg.emoji-item-eye {
    --t: 14px;
    --sc: -1;
  }

  .emojigroup li.emoji-item-happy:hover div:after,
  .emojigroup li.emoji-item-happy.emoji-item--active div:after {
    --middle-s-x: 0.95;
    --middle-s-y: 0.75;
    animation: toggle 0.8s linear forwards;
  }

  .emojigroup li:not(.active) {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .emojigroup li:not(.active):active {
    transform: scale(0.925);
  }

  .emojigroup li:hover,
  .emojigroup li.emoji-item--active {
    --sb: var(--active);
    --sh: var(--active-shadow);
    --m: var(--active-mouth);
    --e: var(--active-eye);
    transform: scale(1.05);
  }

  .emojigroup li:hover div,
  .emojigroup li.emoji-item--active div {
    animation: shake 0.8s linear forwards;
  }

  @keyframes shake {
    30% {
      transform: perspective(240px) rotateX(var(--step-1-rx, 0deg))
        rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg))
        translateZ(10px);
    }
    60% {
      transform: perspective(240px) rotateX(var(--step-2-rx, 0deg))
        rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg))
        translateZ(10px);
    }
    100% {
      transform: perspective(240px) translateZ(4px);
    }
  }

  @keyframes tear {
    0% {
      opacity: 0;
      transform: translateY(-2px) scale(0) translateZ(0);
    }
    50% {
      transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
    }
    20%,
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(24px) translateX(4px) rotateZ(-30deg)
        scale(0.7, 1.1) translateZ(0);
    }
  }

  @keyframes toggle {
    50% {
      transform: translateY(var(--middle-y, 0))
        scale(
          var(--middle-s-x, var(--middle-s, 1)),
          var(--middle-s-y, var(--middle-s, 1))
        )
        rotate(var(--middle-r, 0deg));
    }
  }

  @keyframes angry {
    40% {
      background: var(--active);
    }
    45% {
      box-shadow: inset 3px -3px 4px var(--active-shadow),
        inset 0 8px 10px var(--active-shadow-angry);
    }
  }
}
