.icon-button {
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: var(--border-radius-60);

  svg path {
    fill: var(--header-contrast-color);
  }

  &:hover {
    background-color: var(--header-contrast-color);

    svg path {
      fill: var(--header-color);
    }
  }
}

[dir="rtl"] .icon-button {
  transform: scaleX(-1);
}
