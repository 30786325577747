.progress-bar-container {
  width: 100%;
  height: 4px;

  .progress-bar-inner {
    background-color: var(--primary-color-66);
    width: auto;
    height: 4px;
    transition: width .5s ease-in-out;
    animation: fadeIn;
    animation-duration: 1.5s;
  }
}