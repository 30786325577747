.upload-form-item {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;

  &-loading {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .dropzone {
    width: 100%;
    text-align: center;
    padding: 20px;
    border: 2px dashed var(--border-color);
    background-color: var(--background-color-darker);
    color: var(--secondary-text-color);
    cursor: pointer;
    border-radius: var(--border-radius-40);

    &--accept {
      border-color: var(--primary-color) !important;
      background-color: var(--primary-color-20) !important;
      color: var(--secondary-text-color);
    }

    &--reject {
      border-color: #d83b01 !important;
    }
  }

  .file-added-button {
    border-radius: var(--border-radius-40);
    background-color: var(--background-color-darker);
    box-sizing: border-box;
    padding: 6px 12px;
    padding-right: 8px;
    width: auto;
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 0px;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 39px;

    svg {
      width: 19px;
      height: 19px;
      object-fit: contain;

      path,
      g {
        fill: var(--primary-text-color);
      }
    }

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      color: var(--primary-text-color);
      font-weight: 400;
      margin-left: 8px;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-of-type {
      margin-right: 0px;
    }

    &-remove {
      cursor: pointer;
      margin-left: 5px;
      width: 27px;
      height: 27px;
      border-radius: var(--border-radius-25);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: var(--background-color-dark);
      }
    }
  }
}

[dir="rtl"] .upload-form-item .file-added-button span {
  margin-left: 0px !important;
  margin-right: 8px;
}

[dir="rtl"] .upload-form-item .file-added-button {
  padding-right: 12px !important;
  padding-left: 8px;
}

[dir="rtl"] .file-added-button-remove {
  margin-left: 0px !important;
  margin-right: 5px;
}