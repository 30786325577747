@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&display=swap");

* {
  font-family: Inter, sans-serif;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body,
html {
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: auto;
}

body.animating,
html.animating {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.grecaptcha-badge {
  display: none !important;
  opacity: 0 !important;
}
